import React from 'react';
// import { fifthSectionWrapper } from "../data";
import Cartoon1 from '../../../components/assets/img/pages/landing/cartoon-2.png';
import Cartoon2 from '../../../components/assets/img/pages/landing/cartoon-3.png';
import Cartoon3 from '../../../components/assets/img/pages/landing/cartoon-4.png';
import Cartoon4 from '../../../components/assets/img/pages/landing/cartoon-5.png';
import Cartoon5 from '../../../components/assets/img/pages/landing/cartoon-6.png';
// import Cartoon5 from '../../components/assets/img/pages/landing/cartoon-2.png';
import Girl from '../../../components/assets/img/pages/landing/girl.png';

const FifthSection = () => {
  const fifthSectionWrapper = {
    Girl: Girl,
    Vetor: [
      {
        IMG: Cartoon1,
        Title: 'Crie seu perfil',
        Text: 'Para participar da rede Gifthy é necessário informar apenas o seu nome, e-mail e criar uma senha. Você também pode acessar por conta Google, Facebook, Instagram ou Linked-in. Depois do cadastro efetuado com sucesso, estará tudo pronto! Bora aproveitar.',
        BigIMG: 'small',
      },
      {
        IMG: Cartoon2,
        Title: 'Defina suas preferências',
        Text: 'Acessando a lista de produtos disponíveis, informe o que você gostaria de receber de presente. É o suficiente para que seus amigos saibam o que lhe deixará feliz. É muito fácil e divertido.',
        BigIMG: 'small',
      },
      {
        IMG: Cartoon3,
        Title: 'Convide seus amigos',
        Text: 'Chame seus amigos para formar a sua rede. Você só precisa buscar pelo nome deles, no campo "pesquisar". Aceito o convite, vocês já podem se divertir juntos!',
        BigIMG: 'big',
      },
      {
        IMG: Cartoon4,
        Title: 'Escolha o endereço',
        Text: 'O presente será entregue no endereço que você escolher. Não se preocupe. Todos os seus dados estarão seguros. Só você e a logística do Gifthy conhecerão.',
        BigIMG: 'big',
      },
      {
        IMG: Cartoon5,
        Title: 'Tudo pronto',
        Text: 'Agora você não esquecerá mais dos aniversários ou de qualquer outro evento que você considere importante. Chega de sorrisos amarelos ou desculpas. Com o Gifthy você vai lembrar de todas as datas que merecem uma atenção especial e vai aproveitar presenteando com mais praticidade, segurança, economia e agilidade. Demonstre todo o seu carinho. O Gifthy encontrará o presente ideal para você!',
        BigIMG: 'big',
      },
    ]
  };
  return (
    <section className="fifth padding-7">
      <div className="wrapper">
        {fifthSectionWrapper.Vetor.map((item, index) => (
          <div key={index.toString()} className="item">
            <span className="left">
              <h1 className="title yellow-font bold">{item.Title}</h1>
              <p className="description blue-font">{item.Text}</p>
            </span>
            <div className="right">
              <img src={item.IMG} alt="cartoon" className={item.BigIMG} />
            </div>
          </div>
        ))}
        <div className="girl-img">
          <img src={fifthSectionWrapper.Girl} alt='mulher presente' />
        </div>
      </div>
    </section>
  );
};

export default FifthSection;